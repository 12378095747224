import { Link, useLocation } from "react-router-dom"
import { Icon, Popup } from "semantic-ui-react"
import "./Sidebar.css"
// import dashboard_icon from "../../assets/images/icons/dashboard_icon.png"
// import task_icon from "../../assets/images/icons/task_icon.png"
// import usercheck_icon from "../../assets/images/icons/usercheck_icon.png"
// import customer_icon from "../../assets/images/icons/customer_icon.png"
// import call_icon from '../../assets/images/icons/call_log.png'
import company_logo from '../../assets/images/imgs/Logo.jpg';
import crm_logo from '../../assets/images/imgs/crm_logo_horizontal.png'
import prioritize from '../../assets/images/icons/Prioritize.png'
import bluePrior from '../../assets/images/icons/Mask group.png'
import { Button, ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap"


const Sidebar = ({ isOpen, onClose, auth }) => {
    const location = useLocation()
    var companyLinks
    if (auth.role === "Company" || auth.role === "Sub Admin") {
        companyLinks = [
            { to: "/", label: "Dashboard", icon: "grid layout" },
            // { to: "/agents", label: "Sub Admin", icon: "users" },
            { to: "/agents", label: "Staff", icon: "users" },
            { to: "/leadlist", label: "Leads", icon: "file alternate" },
            { to: "/followups", label: "Followups", icon: "file alternate" },
            // { to: "/walkins", label: "Walk Ins", icon: "file alternate" },
            { to: "/customer", label: "Customers", icon: "users" },
            { to: "/tasks", label: "Tasks", icon: prioritize },  
            { to: "/reports", label: "Reports", icon: "file alternate" },
            { to: "/calllog", label: "Call History", icon: "file alternate" },          
        ]
    }  else if (auth.role === "Admin") {
        companyLinks = [
            { to: "/", label: "Dashboard", icon: "grid layout" },
            { to: "/list-company", label: "Company List", icon: "users" },
            { to: "/add-company", label: "New Company", icon: "file alternate" }, 
            { to: "/demo-requests", label: "Demo Requests", icon: "users" },
            { to: "/id-config", label: "ID Config", icon: prioritize },    
            { to: "/voxbay-api", label: "Voxbay API", icon: "tty" },        
        ]
    } else {
        companyLinks = [
            { to: "/", label: "Dashboard", icon: "grid layout" },
            { to: "/leadlist", label: "Lead List", icon: "file alternate" },
            { to: "/followups", label: "Followups", icon: "file alternate" },
            // { to: "/walkins", label: "Walk In List", icon: "file alternate" },
            { to: "/customer", label: "Customer", icon: "users" },
            { to: "/tasks", label: "Tasks", icon: prioritize },
            // { to: "/reports", label: "Reports", icon: "file alternate" },
            { to: "/calllog", label: "Call Logs", icon: "file alternate" },            
        ]
    }
    // const staffLinks = [
    //     { to: "/", label: "Dashboard", icon: dashboard_icon },
    //     { to: "/leadlist", label: "Lead List", icon: task_icon },
    //     { to: "/followups", label: "Followup List", icon: usercheck_icon },
    //     { to: "/customer", label: "Customer", icon: customer_icon },
    //     { to: "/tasks", label: "Tasks", icon: prioritize }, 
    //     { to: "/calllog", label: "Call Logs", icon: call_icon },           

    // ]
    return (
        auth?.role == "Company" || auth?.role == "Admin" ?
            <div className={`sidebar my-2 company ${isOpen ? 'open ms-0 ms-md-3' : ''}`}>
                <SidebarCompany onClose={onClose} links={companyLinks} location={location} />
            </div>
            :
            <div className={`sidebar agent px-0 ${isOpen ? 'open ms-3' : ''}`}>
                <SidebarAgent onClose={onClose} links={companyLinks} location={location} />
            </div>
    )
}

const SidebarCompany = ({ links, location, onClose }) => {
    return (
        <div className='position-relative p-1'>

            <div className='h4 mt-5 sidebar-header'>
                <img src={crm_logo} className="sidebar-logo-img " alt="" />
            </div>
            <div className="link-container mt-4">
                <ul className="sidebar-items">
                    {links.map((link, index) =>
                        <li key={index} className="">
                            <Link onClick={window.innerWidth < 768 && onClose} className={location.pathname === link.to ? "py-2 active-company company" : "compnay py-2"} to={link.to} aria-expanded="false">

                                {link.to !== "/tasks"&&link.to !== "/id-config" ?<Icon name={link.icon}/>:<img src={location.pathname === link.to?bluePrior:link.icon} className="mx-1 mb-2" width={15} alt="" />}
                                <span className=" fs-5">  {link.label}</span>
                            </Link>
                        </li>)
                    }
                </ul>
            </div>
        </div>
    )
}
const SidebarAgent = ({ links, location, onClose }) => {
    return (
        <div className='position-relative p-1'>
            <div className='mt-5 sidebar-header col-12'>
                <img src={crm_logo} className="sidebar-logo-img " alt="" />
            </div>
            <div className="link-container mt-5">
                <ul className="sidebar-items">
                    {links.map((link, index) =>
                        <li 
                            key={index} 
                            className={
                                location.pathname === link.to ? 
                                "agent active-agent" : 
                                "agent"
                            }>
                            <Link 
                                onClick={window.innerWidth < 768 && onClose} 
                                to={link.to} 
                                aria-expanded="false"
                            >

                                {link.to !== "/tasks"&&link.to !== "/id-config" ?
                                    <Icon name={link.icon}/>:
                                    <img 
                                        src={location.pathname === link.to?bluePrior:link.icon} 
                                        className="mx-1 mb-2" 
                                        width={15} 
                                        alt="" 
                                    />
                                }
                                <span className=" fs-5">  {link.label}</span>
                            </Link>
                        </li>)
                    }
                </ul>
            </div>
        </div>
    )
}

export default Sidebar