import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import useLeadServices from "../../../services/leadServices";
import Swal from "sweetalert2";
import { Switch } from "antd";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";

const MetaSettings = ({ auth }) => {
  const [createModal, setCreateModal] = useState(false);
  const [metaData, setMetaData] = useState({
    id: null, // Add id field to track the edited meta item
    page_name: "",
    page_id: "",
    page_access_token: "",
    active: false,
  });
  const [meta, setMeta] = useState([]);

  const { postMetaDetails, getMetaDetails, deleteMeta, putMetaDetails } =
    useLeadServices();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (metaData.id === null) {
        // If id is null, it means it's a new meta item
        const response = await postMetaDetails(metaData);
        if (response.success) {
          Swal.fire("", "Page Details Added Successfully", "success");
          setCreateModal(false);
          fetchMetaDetails();
          resetForm();
        } else {
          Swal.fire("", "Failed to add page details", "error");
        }
      } else {
        // If id is not null, it means it's an update operation
        const response = await putMetaDetails(metaData.id, metaData);
        if (response.success) {
          Swal.fire("", "Page Details Updated Successfully", "success");
          setCreateModal(false);
          fetchMetaDetails();
          resetForm();
        } else {
          Swal.fire("", "Failed to update page details", "error");
        }
      }
    } catch (err) {
      Swal.fire("", "Something went wrong!!", "error");
    }
  };

  const resetForm = () => {
    setMetaData({
      id: null,
      page_name: "",
      page_id: "",
      page_access_token: "",
      active: false,
    });
  };

  const handleEdit = (item) => {
    // Populate the form fields with the details of the selected meta item
    setMetaData({
      id: item.id,
      page_name: item.page_name,
      page_id: item.page_id,
      page_access_token: item.page_access_token,
      active: item.active,
    });
    setCreateModal(true);
  };

  const handleCreateOpen = () => setCreateModal(true);

  const handleCreateClose = () => {
    setCreateModal(false);
    resetForm();
  };

  const fetchMetaDetails = async () => {
    try {
      const response = await getMetaDetails();
      setMeta(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMetaDetails();
  }, []);

  const handleSwitchChange = async (checked, itemId) => {
    try {
      const response = await putMetaDetails(itemId, { active: checked });
      if (response.success) {
        Swal.fire("", "Meta Data Updated Successfully", "success");
        fetchMetaDetails(); // Fetch meta details again to update the UI
      } else {
        Swal.fire("", "Failed to update meta data", "error");
      }
    } catch (error) {
      console.error(error);
      Swal.fire("", "Something went wrong!!", "error");
    }
  };

  const deleteMetaById = async (id) => {
    try {
      // Show confirmation popup
      const result = await Swal.fire({
        title: "Do you want to delete the Meta Page ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        icon: "question",
      });

      if (result.isConfirmed) {
        const response = await deleteMeta(id);
        fetchMetaDetails();

        if (response?.success) {
          Swal.fire("Success!", "Meta Data Deleted Successfully", "success");
        } else {
          Swal.fire("Error!", response?.message, "error");
        }
      } else if (result.isDenied) {
        Swal.fire("Action Cancelled", "Failed to delete meta data", "info");
      }
    } catch (error) {
      Swal.fire("Error!", "Something went wrong!!", "error");
    }
  };

  return (
    <>
      <div className="row position-relative mb-2 ms-4">
        <h5 className="m-0 p-0 mt-2">Integration and Connected Pages</h5>
        <button
          onClick={handleCreateOpen}
          className="btn col-12 col-md-2 position-absolute me-3 btn-pill end-0 pill"
          style={{
            backgroundColor: "#3b3367",
            color: "white",
            width: "180px",
            height: "40px",
            borderRadius: "10px",
          }}
        >
          <FaPlus size="14px" /> Add Meta Pages
        </button>
        <Modal
          show={createModal}
          onHide={handleCreateClose}
          centered={true}
          size="md"
          contentClassName=""
        >
          <Modal.Body style={{ height: "400px" }}>
            <Form onSubmit={handleSubmit}>
              <div className="p-2">
                <h4>{metaData.id ? "Edit" : "Add"} Meta Pages</h4>
                <div className="my-3">
                  <Form.Group controlId="pageName">
                    <Form.Label>Page Name</Form.Label>
                    <Form.Control
                      value={metaData.page_name}
                      onChange={(e) =>
                        setMetaData({ ...metaData, page_name: e.target.value })
                      }
                      className="py-3"
                      placeholder="Name"
                      style={{ backgroundColor: "#ebebeb" }}
                    />
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-between">
                  <Form.Group controlId="pageID">
                    <Form.Label>Page ID</Form.Label>
                    <Form.Control
                      value={metaData.page_id}
                      onChange={(e) =>
                        setMetaData({ ...metaData, page_id: e.target.value })
                      }
                      className="py-3"
                      placeholder="ID"
                      style={{ backgroundColor: "#ebebeb" }}
                    />
                  </Form.Group>
                  <Form.Group controlId="pageToken">
                    <Form.Label>Page Access Token</Form.Label>
                    <Form.Control
                      value={metaData.page_access_token}
                      onChange={(e) =>
                        setMetaData({
                          ...metaData,
                          page_access_token: e.target.value,
                        })
                      }
                      className="py-3"
                      placeholder="Token"
                      style={{ backgroundColor: "#ebebeb" }}
                    />
                  </Form.Group>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-start mt-4">
                  <p>
                    Inactive/Active
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      checked={metaData.active}
                      onChange={(checked) =>
                        setMetaData({
                          ...metaData,
                          active: checked,
                        })
                      }
                    />
                  </p>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-end mt-5">
                  <div className="">
                    <div
                      onClick={handleCreateClose}
                      className="btn btn-danger mx-2"
                    >
                      Cancel
                    </div>
                    <button
                      type="submit"
                      className={`btn btn-primary ${
                        auth.theme === "companyTheme" ? "" : "agent"
                      }`}
                    >
                      Save Page
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="row my-3 p-2">
        <div className="settings-table-container mt-3 bg-white px-4 w-100 d-flex flex-wrap gap-3">
          {meta.map((item) => (
            <div
              key={item.id}
              className="meta-item p-3 d-flex align-items-center justify-content-between"
              style={{
                border: "2px solid #DCDCDC",
                borderRadius: "8px",
                width: "370px",
                height: "100px",
              }}
            >
              <div
                className="d-flex flex-column align-items-start justify-content-center"
                style={{ width: "140px" }}
              >
                <p>Name: {item.page_name}</p>
                <p>ID: {item.page_id}</p>
              </div>
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={item.active}
                onChange={(checked) => handleSwitchChange(checked, item.id)}
              />
              <div>
                <EditOutlined onClick={() => handleEdit(item)} />
                <DeleteOutlined onClick={() => deleteMetaById(item.id)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MetaSettings;
