import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import useAxios from "../hooks/useAxios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

//check if company exists or not
export const adminCheck = async () => {
  try {
    const response = await axios.get("/account/admin_check/");
    return response.data;
  } catch (err) {
    throw new Error("Admin Already Exists");
  }
};

export const logout = async (data) => {
  try {
    const response = await axios.post("/account/logout/", data);
    return response.data;
  } catch (err) {
    throw new Error("Login fialed");
  }
};

const useBaseServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const axios = useAxios();
  const { auth } = useAuth();

  //login api
  const login = async (data) => {
    try {
      const response = await axios.post("/account/login/", data);
      return response.data;
    } catch (err) {
      throw new Error("Login fialed");
    }
  };

  //register new agent
  const postSubAdminRegister = async (data) => {
    const response = await axiosPrivate.post(
      "/account/subadmin_register/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //register new agent
  const postAgentRegister = async (data) => {
    const response = await axiosPrivate.post("/account/agent_register/", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  };

  //get staff details at first login
  const getFirstPasswordDetails = async () => {
    const response = await axiosPrivate.get("/account/set_first_password/");
    return response.data;
  };

  //get staff profile details
  const getStaffProfile = async () => {
    const response = await axiosPrivate.get("/account/profile/", {
      params: { user_id: auth.staffId },
    });
    return response.data;
  };

  //get company email configuration details
  const getCompanyEmailConfig = async () => {
    const response = await axiosPrivate.get(
      "/account/email_confg/view/company/"
    );
    return response.data;
  };

  //get all agent numbers
  const getAllAgentNumbers = async () => {
    const response = await axiosPrivate.get("/account/extension/create/");
    return response.data;
  };

  //get all Active and unassigned agent numbers
  const getActiveAgentNumbers = async () => {
    const response = await axiosPrivate.get("/account/extension/active/");
    return response.data;
  };

  //get assigned agent number (company access) (fkuser id)
  const getAssignAgentNumber = async (id) => {
    const response = await axiosPrivate.get(
      "/account/extension/assign/" + id + "/"
    );
    return response.data;
  };

  //get assigned agent number (all users)
  const getAssignedAgentNumber = async (id) => {
    const response = await axiosPrivate.get("/account/extension/assigned/");
    return response.data;
  };

  //get staff list
  const getStaffList = async (data) => {
    const response = await axiosPrivate.get("/account/staff_list/", {
      params: { ...data },
    });
    return response.data;
  };

  //get dashboard
  const getDashboard = async (data) => {
    const response = await axiosPrivate.get("/account/dashboard/", {
      params: { ...data },
    });
    return response.data;
  };

  //get staff detail
  const getStaffDetails = async (id) => {
    const response = await axiosPrivate.get(
      "/account/staff_details/" + id + "/"
    );
    return response.data;
  };

  //get lead assign
  const getLeadAssign = async (
    id,
    choice,
    date,
    from,
    to,
    search,
    page,
    item
  ) => {
    const response = await axiosPrivate.get(
      "/account/lead_assign/" + id + "/",
      {
        params: {
          choice: choice,
          date: date,
          from: from,
          to: to,
          search,
          page: page,
          item: item,
        },
      }
    );
    return response.data;
  };

  //create task
  const postTask = async (data) => {
    const response = await axiosPrivate.post("/account/task/create/", data);
    return response.data;
  };

  //get created task list
  const getCreatedTaskList = async () => {
    const response = await axiosPrivate.get("/account/task/create/");
    return response.data;
  };

  //get task (creator)
  const getTaskCreator = async (id) => {
    const response = await axiosPrivate.get(
      `/account/task_edit/creator/${id}/`
    );
    return response.data;
  };

  //get staff task list
  const getStaffTaskList = async (id) => {
    const response = await axiosPrivate.get(
      `/account/task_list/creator/${id}/`,
      {
        params: { user_id: auth.staffId },
      }
    );
    return response.data;
  };

  //edit task (creator)
  const putTaskCreator = async (id, data) => {
    const response = await axiosPrivate.put(
      `/account/task_edit/creator/${id}/`,
      data
    );
    return response.data;
  };

  //edit task (user)
  const putTaskUser = async (id, data) => {
    const response = await axiosPrivate.put(
      `/account/task_edit/user/${id}/`,
      data
    );
    return response.data;
  };

  //get task (user)
  const getTaskUser = async (id) => {
    const response = await axiosPrivate.get(`/account/task_edit/user/${id}/`);
    return response.data;
  };

  //get user task list
  const getUserTaskList = async () => {
    const response = await axiosPrivate.get(`/account/task_list/user/`, {
      params: { user_id: auth.staffId },
    });
    return response.data;
  };

  //get lead monthly report
  const getLeadMonthlyReport = async () => {
    const response = await axiosPrivate.get(`/account/report/lead_monthly/`, {
      params: { user_id: auth.staffId },
    });
    return response.data;
  };

  //get staff monthly report
  const getStaffMonthlyReport = async (id) => {
    const response = await axiosPrivate.get(
      `/account/report/staff_monthly/${id}/`,
      {
        params: { user_id: auth.staffId },
      }
    );
    return response.data;
  };

  //get staff lead report
  const getStaffLeadReport = async (id) => {
    const response = await axiosPrivate.get(
      `/account/report/staff_lead/${id}/`,
      {
        params: { user_id: auth.staffId },
      }
    );
    return response.data;
  };

  //create or edit company email configuration details
  const postCompanyEmailConfig = async (data) => {
    const response = await axiosPrivate.post(
      "/account/email_confg/view/company/",
      data
    );
    return response.data;
  };

  //add new agent number and extension
  const postNewAgentNumber = async (data) => {
    const response = await axiosPrivate.post(
      "/account/extension/create/",
      data
    );
    return response.data;
  };

  //assign new agent number and extension
  const postAssignAgentNumber = async (id, data) => {
    const response = await axiosPrivate.post(
      "/account/extension/assign/" + id + "/",
      data
    );
    return response.data;
  };

  //post forget password otp
  const postForgotPasswordOTP = async (id, data) => {
    const response = await axiosPrivate.post(
      "/account/forgot_password/verify_otp/" + id + "/",
      data
    );
    return response.data;
  };

  //assign and unassign lead
  const postLeadAssign = async (id, data) => {
    const response = await axiosPrivate.post(
      "/account/lead_assign/" + id + "/",
      data
    );
    return response.data;
  };

  //edit staff details at first login
  const putFirstPasswordDetails = async (data) => {
    const response = await axiosPrivate.put(
      "/account/set_first_password/",
      data
    );
    return response.data;
  };

  // password change
  const putPasswordChange = async (data) => {
    const response = await axiosPrivate.put("/account/change_password/", data);
    return response.data;
  };

  //edit staff profile details
  const putStaffProfile = async (data) => {
    const response = await axiosPrivate.put("/account/profile/", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  };

  //Change Agent Number Status
  const putChangeAgentNumberStatus = async (id) => {
    const response = await axiosPrivate.put(
      "/account/extension/status/" + id + "/"
    );
    return response.data;
  };

  //put forget password username
  const putForgotPasswordUsername = async (data) => {
    const response = await axiosPrivate.put("/account/forgot_password/", data);
    return response.data;
  };

  //put forget password new password
  const putForgotPasswordNewPwd = async (id, data) => {
    const response = await axiosPrivate.put(
      "/account/forgot_password/password_change/" + id + "/",
      data
    );
    return response.data;
  };

  //edit staff detail
  const putStaffDetails = async (id, data) => {
    const response = await axiosPrivate.put(
      "/account/staff_details/" + id + "/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //delete Agent Number
  const deleteAgentNumber = async (id) => {
    const response = await axiosPrivate.delete(
      "/account/extension/delete/" + id + "/"
    );
    return response.data;
  };

  //unassign Agent Number
  const deleteUnassignAgentNumber = async (id) => {
    const response = await axiosPrivate.delete(
      "/account/extension/assign/" + id + "/"
    );
    return response.data;
  };

  //delete staff
  const deleteStaffDetails = async (id) => {
    const response = await axiosPrivate.delete(
      "/account/staff_details/" + id + "/"
    );
    return response.data;
  };

  //delete task (creator)
  const deleteTaskCreator = async (id) => {
    const response = await axiosPrivate.delete(
      `/account/task_edit/creator/${id}/`
    );
    return response.data;
  };

  //returns
  return {
    //get requests
    getFirstPasswordDetails,
    getStaffProfile,
    getCompanyEmailConfig,
    getAllAgentNumbers,
    getActiveAgentNumbers,
    getAssignAgentNumber,
    getAssignedAgentNumber,
    getStaffList,
    getDashboard,
    getStaffDetails,
    getLeadAssign,
    getCreatedTaskList,
    getTaskCreator,
    getStaffTaskList,
    getTaskUser,
    getUserTaskList,
    getLeadMonthlyReport,
    getStaffMonthlyReport,
    getStaffLeadReport,

    //post requests
    postCompanyEmailConfig,
    postNewAgentNumber,
    postAssignAgentNumber,
    postForgotPasswordOTP,
    postLeadAssign,
    postTask,
    postSubAdminRegister,
    postAgentRegister,
    login,

    //put requests
    putFirstPasswordDetails,
    putPasswordChange,
    putStaffProfile,
    putChangeAgentNumberStatus,
    putForgotPasswordUsername,
    putForgotPasswordNewPwd,
    putStaffDetails,
    putTaskCreator,
    putTaskUser,

    //delete requests
    deleteAgentNumber,
    deleteUnassignAgentNumber,
    deleteStaffDetails,
    deleteTaskCreator,
  };
};
export default useBaseServices;
