import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import { Layout } from "./components/layout/Layout";
import Login from "./pages/login/Login";
// import Register from './pages/register/Register';
import Leads from "./pages/leads/Leads";
import Reports from "./pages/reports/Reports";
import Staff from "./pages/staff/Staff";
import Agents from "./pages/agents/Agents";
import RequireAuth from "./components/auth/RequireAuth";
import FollowUps from "./pages/folowups/FollowUps";
import Profile from "./pages/profile/Profile";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
} from "chart.js";
import Customer from "./pages/customer/Customer";
import Settings from "./pages/settings/Settings";
import DigitalCard from "./pages/digitalcard/DigitalCard";
import CallLog from "./pages/calllog/CallLog";
import Notification from "./pages/notification/Notification";
import { Task } from "./pages/tasks/Task";
import AdminCompanyForm from "./pages/admin/pages/company-form/AdminCompanyForm";
import AdminCompanyList from "./pages/admin/pages/company-list/AdminCompanyList";
import DemoRequestList from "./pages/admin/pages/company-list/DemoRequestList";
import IDConfig from "./pages/admin/pages/id-config/IDConfig";
import VoxbayData from "./pages/admin/pages/voxbay-data/VoxbayData";
import WalkIns from "./pages/walk-ins/WalkIns";
import Register from "./pages/demoRequest/Register";
import Report from "./pages/report/Report";

function App() {
  //register chart elements
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path='/register' element={<Register />} /> */}
            <Route path="/" element={<Layout />}>
              <Route element={<RequireAuth />}>
                <Route index element={<Dashboard />} />
                {/* <Route path='/staff' element={<Staff />} /> */}
                <Route path="/leadlist" element={<Leads />} /> 
                <Route path="/followups" element={<FollowUps />} />
                <Route path="/walkins" element={<WalkIns />} />
                <Route path="/agents" element={<Agents />} />
                {/* <Route path="/reports" element={<Reports />} /> */}
                <Route path="/reports" element={<Report />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/customer" element={<Customer />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/calllog" element={<CallLog />} />
                <Route path="/tasks" element={<Task />} />
                <Route path="/add-company" element={<AdminCompanyForm />} />
                <Route path="/list-company" element={<AdminCompanyList />} />
                <Route path="/demo-requests" element={<DemoRequestList />} />
                <Route path="/voxbay-api" element={<VoxbayData />} />
                <Route path="/id-config" element={<IDConfig />} />
              </Route>
            </Route>
            <Route path="/register/" element={<Register />} />
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}

export default App;
